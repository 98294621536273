define('ndui/nutrition/with_get_quantity',[],function () {
  return function withGetQuantity() {
    this.getQuantity = function ($e) {
      var tagName = $e.prop('tagName').toLowerCase();
      var $base;
      var quantity = 1, qty;

      if (tagName === 'input') {
        $base = $e;
        var type = $e.prop('type').toLowerCase();
        if (type === 'checkbox' || type === 'radio') {
          if (!$e.prop('checked')) {
            return 0;
          }
        } else if ($e.is('.js-nutrition-multiplier')) {
          qty = parseInt($e.val(), 10);
          quantity = isNaN(qty) ? 0 : qty;
        }
      }
      else if (tagName === 'select') {
        $base = $e;
        var $sel = $e.find(':selected');
        if ($e.is('.js-nutrition-multiplier')) {
          qty = parseInt($sel.val(), 10);
          quantity = isNaN(qty) ? 0 : qty;
        } else if (!$sel.val()) {
          return 0;
        }
      }
      else if (tagName === 'option') {
        $base = $e.closest('select');
        if (!$e.prop('selected')) {
          return 0;
        }
      } else {
        $base = $e;
      }

      if ($base.prop('disabled')) {
        return 0;
      }
      if ($base.is(':hidden') || !$base.is(':visible')) {
        return 0;
      }

      return quantity;
    };
  };
});

