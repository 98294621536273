define('ndmodel/restaurant',['require','lodash','moment','ndlib/api'],function (require) {
  var _ = require('lodash');
  var moment = require('moment');

  var ndApi = require('ndlib/api');

  function Restaurant(raw) {
    _.extend(this, raw);
  }

  Restaurant.load = function (restaurantId) {
    return ndApi.getLocationInformation({
      restaurantId: restaurantId,
      serviceHours: true
    })
    .then(function (raw) {
      var r = new Restaurant(raw);
      calculate_restaurant_time_offsets(r);
      r.serviceSchedule = fix_service_schedule(r.service_schedule);
      r.services.forEach(function (service) {
        service.schedule = r.serviceSchedule[service.service_type_id];
      });
      return r;
    });
  };


  Restaurant.availableRanges = function (sched, start, end, trimEnds, limit,
                                         ignorePastBusinessDates) {
    var avail = [];
    if (!sched) { return []; }

    if (!end) { end = start; }
    if (!limit) { limit = sched.length; }

    // Time range possibilities:
    // ----------------------------------------
    //              s          e
    //  1:  rs  re  |          |            ignore
    //  2:  rs      |      re  |            use s  - re
    //  3:  rs      |          |      re    use s  - e
    //  4:          |  rs  re  |            use rs - re
    //  5:          |  rs      |      re    use rs - e
    //  6:          |          |  rs  re    ignore
    var s = start.valueOf();
    var e = end.valueOf();
    if (e <= s) {
      return [];
    }

    var businessDateLimit = 0;
    if (ignorePastBusinessDates) {
      businessDateLimit = moment(start).startOf('day').valueOf();
    }

    for (var i = 0, ii = sched.length; i < ii && avail.length < limit; i += 1) {
      var rangeStart = sched[i][0], rs = rangeStart.valueOf();
      var rangeEnd = sched[i][1], re = rangeEnd.valueOf();

      if (rangeStart && rangeStart.businessDate) {
        if (rangeStart.businessDate.valueOf() < businessDateLimit) {
          continue;
        }
      }

      if (rs <= s) {
        if (re <= s) {
          // Case 1 - ignore
        } else if (re < e) {
          // Case 2
          avail.push([start, rangeEnd]);
        } else {
          // Case 3
          avail.push([start, trimEnds ? end : rangeEnd]);
        }
      } else if (rs < e) {
        if (re < e) {
          // Case 4
          avail.push([rangeStart, rangeEnd]);
        } else {
          // Case 5
          avail.push([rangeStart, trimEnds ? end : rangeEnd]);
        }
      } else {
        // Case 6
      }
    }

    return avail;
  };

  Restaurant.prototype.local_time = function (now, offset) {
    return new Date(
      now + this.local_time_offset + 60000 * (offset ? offset : 0));
  };

  Restaurant.prototype.real_time = function (now, offset) {
    return new Date(
      now + this.real_time_offset + 60000 * (offset ? offset : 0));
  };

  Restaurant.prototype.next_available_order_time = function (now, service_type_id, prep_time, padding) {
    var service = this.services.filter(function (s) {
      return s.service_type_id == service_type_id;
    })[0];
    var lead_time = Math.max(service.lead_time, prep_time || 0) + (padding || 0);
    var earliest_by_lead_time = moment(now).add('minutes', lead_time).toDate();
    var latest = moment(now).endOf('day').toDate();
    var available = this.available_service_ranges(
      service_type_id,
      earliest_by_lead_time,
      latest,
      true,
      1);
    if (!available.length) { return; }
    var next = moment(available[0][0]);
    next.minute(5 * Math.ceil(next.minutes() / 5));
    return next.toDate();
  };

  Restaurant.prototype.available_service_ranges =
  function (serviceTypeId, start, end, trimEnds, limit) {
    var sched = this.serviceSchedule[serviceTypeId];
    return Restaurant.availableRanges(sched, start, end, trimEnds, limit);
  };


  // Private

  function calculate_restaurant_time_offsets(restaurant) {
    // Act like the user's timezone and the restaurant's timezone are the
    // same, even if they are not.  This makes the JS Date correct for
    // local time operations, but potentially incorrect for UTC operations.
    // It also makes DST work correctly, as long as the user's timezone and
    // the restaurant's timezone behave the same.

    var now = new Date();
    var m;

    m = restaurant.today.match(/(\d+)\/(\d+)\/(\d+)/);
    var yr = +m[3], mon = (+m[1]) - 1, day = +m[2];

    m = restaurant.localtime.match(/\s?(\d+):(\d+)\s([aApP][mM])/);
    var hr = +m[1], min = +m[2], am_pm = m[3];
    if (hr == 12) { hr = 0; }
    if (am_pm.toLowerCase() == 'pm') { hr += 12; }

    // We could be anywhere in the minute.  Estimate half-way through so
    // we're 30 seconds off, worst-case.
    var local_time = new Date(yr, mon, day, hr, min, 31);
    restaurant.local_time_offset = local_time - now;

    var tz_offset = 0;
    m = restaurant.timezone.match(/GMT([-+]\d+)/);
    if (m) { tz_offset = (+m[1]) * 60; }
    // getTimezoneOffset() and tz_offset are backwards from each other,
    // so add rather than subtract.
    restaurant.real_time_offset =
      restaurant.local_time_offset -
      60000 * (local_time.getTimezoneOffset() + tz_offset);
  }

  function fix_service_schedule(raw_schedule) {
    var service_schedule = {};
    _.forEach(raw_schedule, function (raw, service_type_id) {
      var s = [];
      _.forEach(raw, function (ranges, date_string) {
        var parts = date_string.match(/(\d+)/g);
        var year = parts[0], mon = parts[1] - 1, day = parts[2];
        var businessDate = new Date(year, mon, day);
        function to_date(minutes) {
          var d = new Date(year, mon, day, 0, minutes);
          d.businessDate = businessDate;
          return d;
        }
        for (var i = 0, ii = ranges.length; i < ii; i += 1) {
          s.push([to_date(ranges[i][0]), to_date(ranges[i][1])]);
        }
      });
      s.sort(function (a, b) { return a[0] - b[0]; });
      service_schedule[service_type_id] = s;
    });
    return service_schedule;
  }

  return Restaurant;
});

