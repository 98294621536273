define('nddata/restaurant',['require','flight/lib/component','jquery','ndmodel/restaurant'],function (require) {
  var defineComponent = require('flight/lib/component');
  var $ = require('jquery');

  var Restaurant = require('ndmodel/restaurant');


  function restaurantData() {
    var restaurant = null;

    this.defaultAttrs({
      restaurantId: false
    });

    this.after('initialize', function () {
      this.on('selectRestaurant', function (ev, data) {
        if (data && data.restaurant) {
          restaurant = data.restaurant;
        }
        else if (data && data.restaurantId) {
          restaurant = Restaurant.load(data.restaurantId);
        }
        else {
          restaurant = null;
        }
        this.trigger('needRestaurantData');
      });

      this.on('needRestaurantData', function () {
        // restaurant could be a promise.
        $.when(restaurant).then(function (r) {
          this.trigger('restaurantData', {restaurant: r});
        }.bind(this));
      });

      if (this.attr.restaurantId) {
        this.trigger('selectRestaurant', {
          restaurantId: this.attr.restaurantId
        });
      }
    });
  }

  return defineComponent(restaurantData);
});

