
// Generated by CoffeeScript 1.7.1
(function() {
  define('cs/cs!nd/data/ordering/delivery',['require','flightjs','jquery','ndlib/api'],function(require) {
    var $, deliveryData, flight, ndApi;
    flight = require("flightjs");
    $ = require("jquery");
    ndApi = require("ndlib/api");
    deliveryData = function() {
      this.attributes({
        addressesDataSelector: null
      });
      this.after("initialize", function() {
        var _ref;
        this.order = null;
        this.suggest = $.when({});
        this.suggestionPostalCode = null;
        this.addresses = (_ref = this.select("addressesDataSelector").data("addresses")) != null ? _ref : [];
        this.lastSearchedAddressId = 0;
        this.on(document, "orderData", this.updateOrderData);
        this.on(document, "uiNeedCustomerAddresses", this.provideCustomerAddresses);
        this.on(document, "uiSaveDeliveryAddress", this.saveAddress);
        return this.on(document, "uiPartialDeliveryAddress", this.suggestAddress);
      });
      this.one = function(selector, eventType, handler) {
        var wrapper;
        wrapper = (function(_this) {
          return function(ev, data) {
            _this.off(selector, eventType, wrapper);
            return handler.call(_this, ev, data);
          };
        })(this);
        return this.on(selector, eventType, wrapper);
      };
      this.provideCustomerAddresses = function() {
        var _ref, _ref1;
        if (this.haveFetchedAddresses) {
          this.trigger("dataCustomerAddresses", {
            addresses: this.addresses
          });
          return;
        }
        if ((_ref = this.order) != null ? _ref.customer_id : void 0) {
          ndApi.getCustomerAddresses({
            customerId: (_ref1 = this.order) != null ? _ref1.customer_id : void 0
          }).then((function(_this) {
            return function(addresses) {
              _this.addresses = addresses;
              _this.haveFetchedAddresses = true;
              return _this.provideCustomerAddresses();
            };
          })(this));
          return null;
        } else {
          return this.one(document, "orderData", this.provideCustomerAddresses);
        }
      };
      this.saveAddress = function(ev, data) {
        var address, params, split_address1, _ref;
        address = data.address;
        split_address1 = /^\s*(\S+)?\s*(.*)$/.exec(address.address1);
        params = {
          customerId: (_ref = this.order) != null ? _ref.customer_id : void 0,
          streetNumber: split_address1[1],
          address1: split_address1[2],
          address2: address.address2,
          city: address.city,
          stateCode: address.state,
          postalCode: address.postalCode,
          phone: address.phone,
          geocode: true,
          return_details: true
        };
        return ndApi.addCustomerAddress(params).then((function(_this) {
          return function(addr) {
            _this.addresses.unshift(addr);
            _this.provideCustomerAddresses();
            return _this.trigger("uiSelectDeliveryAddress", {
              destinationId: addr.addressid
            });
          };
        })(this));
      };
      this.suggestAddress = function(ev, data) {
        var postalCode, _ref;
        postalCode = (_ref = data.address) != null ? _ref.postalCode : void 0;
        if (!postalCode) {
          return;
        }
        if (postalCode !== this.suggestionPostalCode) {
          this.suggestionPostalCode = postalCode;
          this.suggest = ndApi.getDefaultsForPostalCode({
            postalCode: postalCode
          }).then((function(_this) {
            return function(raw) {
              var addr, _ref1, _ref2;
              if (raw.count) {
                addr = raw.rows[0];
                addr.address = ("" + ((_ref1 = addr.stNum) != null ? _ref1 : '') + " " + ((_ref2 = addr.address1) != null ? _ref2 : '')).trim();
                return addr;
              }
            };
          })(this));
        }
        return this.suggest.then((function(_this) {
          return function(data) {
            return _this.trigger("dataDeliveryAddressSuggestion", {
              address: data
            });
          };
        })(this));
      };
      return this.updateOrderData = function(ev, data) {
        var addressId, search;
        search = (function(_this) {
          return function(addressId) {
            var address, _ref;
            address = _this.addresses.filter(function(a) {
              return a.addressid === addressId;
            })[0];
            if (address) {
              _this.lastSearchedAddressId = addressId;
              _this.trigger("dataDeliveryRestaurants", {
                searching: true
              });
              return ndApi.searchDeliveryRestaurants({
                latitude: address.latitude,
                longitude: address.longitude,
                timeStamp: (_ref = _this.order) != null ? _ref.ready_time : void 0
              }).then(function(available) {
                var m, rest_id, _ref;
                rest_id = (_ref = _this.order) != null ? _ref.restaurant_id : void 0;
                m = available.filter(function(r) {
                  return r.restaurantid === rest_id;
                });
                return _this.trigger("dataDeliveryRestaurants", {
                  deliveryOK: m.length > 0,
                  availableRestaurants: available,
                  deliveryRestaurant: m[0]
                });
              });
            } else {
              _this.lastSearchedAddressId = 0;
              return _this.trigger("dataDeliveryRestaurants", {
                deliveryOK: false,
                availableRestaurants: []
              });
            }
          };
        })(this);
        this.order = data.order;
        addressId = this.order.destination_id || 0;
        if (addressId !== this.lastSearchedAddressId) {
          setTimeout((function() {
            return search(addressId);
          }), 0);
        }
        return null;
      };
    };
    return flight.component(deliveryData);
  });

}).call(this);

