define('nd/ui/auth/sign_in',['require','flightjs','handlebars','./with_foreign_auth'],function (require) {
  var flight = require("flightjs");
  var Handlebars = require("handlebars");

  var withForeignAuth = require("./with_foreign_auth");

  return flight.component(signIn, withForeignAuth);

  function signIn() {
    this.attributes({
      formSelector: ".js-sign-in-form",
      usernameSelector: "input[name='username']",
      passwordSelector: "input[name='password']",
      errorMsgSelector: ".js-sign-in-error-msg",
      foreignAuthTemplateSelector: "#foreign-auth-providers-template"
    });

    this.after("initialize", function () {
      this.on(this.select("formSelector"), "submit", this.signIn);
      this.on(
        this.select("formSelector").find("input[type='reset']"),
        "click",
        this.cancel
      );
      this.on(document, "userActionError", this.handleError);

      this.errorMsgTimeout = null;
    });

    this.foreignAuthProvidersUpdated = function (providers) {
      console.log("Got providers: %o", providers);
      var $template = $(this.attr.foreignAuthTemplateSelector);
      var template = Handlebars.compile($template.html());
      $('.js-foreign-auth-providers-container').html(template({
        foreignAuthProviders: providers
      }));
      this.announceProvidersWereRendered(providers);
    };

    this.signIn = function (ev) {
      ev.preventDefault();
      this.select("errorMsgSelector").hide();
      this.trigger("signIn", {
        username: this.select("usernameSelector").val(),
        password: this.select("passwordSelector").val()
      });
    };

    this.cancel = function (ev) {
      ev.preventDefault();
      this.trigger("customerCanceledAuthentication");
    };

    this.handleError = function (ev, data) {
      if (data && data.action === "signIn") {
        this.select("errorMsgSelector").text(data.message).show();
      }
    };
  }
});

