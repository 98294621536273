define('nddata/order',['require','jquery','moment','flightjs','ndmodel/order'],function (require) {
  var $ = require('jquery');
  var moment = require('moment');
  var flight = require('flightjs');

  var Order = require('ndmodel/order');


  function orderData() {
    this.attributes({
    });

    this.after('initialize', function () {
      this.order = {};
      this.restaurant = {};
      this.service = {};

      var orderData = $('[data-order-data]').attr('data-order-data');
      var restData = $('[data-restaurant-data]').attr('data-restaurant-data');
      if (orderData && restData) {
        this.order = JSON.parse(orderData);
        this.restaurant = JSON.parse(restData);
        this.service = this.restaurant.services[this.order.servicetype_id];
      }

      this.on('needOrderData', function () {
        this.fireOrderData();
      });

      this.on('needCustomerOrders', function (ev, data) {
        Order.getCustomerOrders(data.customerId, true, 20).then(function (o) {
          this.trigger('customerOrdersData', { orders: o });
        }.bind(this));
      });

      this.on('needSavedOrders', function (ev, data) {
        var gettingOrders = Order.getSavedOrders(data.customerId, true, 20);
        var gettingItems = Order.getCustomerFavorites(data.customerId);
        $.when(gettingOrders, gettingItems).done(function (orders, items) {
          this.trigger('savedOrdersData', {
            orders: orders[0],
            items: items[0]
          });
        }.bind(this));
      });

      this.on('customerAuthenticated', function (ev, data) {
        this.order.customer_id = data.customerId;
        this.fireOrderData();
      });

      this.on('restaurantData', function (ev, data) {
        this.restaurant = data.restaurant;
        if (this.restaurant) {
          this.order.restaurant_id = this.restaurant.restaurantid;
        }
        this.fireOrderData();
      });

      this.on('serviceData', function (ev, data) {
        this.service = data.service;
        if (this.service) {
          this.order.servicetype_id = this.service.service_type_id;
        }
        this.fireOrderData();
      });

      this.on('editOrder', function (ev, data) {
        this.order.base_order_id = parseInt(data.orderId, 10);
        if (data.prepTime) {
          this.order.prep_time = parseInt(data.prepTime, 10);
        }
        this.fireOrderData();
      });

      this.on('selectOrderReadyTime', function (ev, data) {
        this.order.ready_time = data.readyTime;
        this.order.is_asap = !!data.isASAP;
        this.fireOrderData();
      });

      this.on('uiSelectDeliveryAddress', function (ev, data) {
        this.order.destination_id = data.destinationId;
        this.isDeliveryOK = false;
        this.fireOrderData();
      });

      this.on('dataDeliveryRestaurants', function (ev, data) {
        this.isDeliverySearching = data.searching;
        this.isDeliveryOK = data.deliveryOK;
        this.fireOrderData();
      });

      this.on('startOrder', this.startOrder);
      this.on('redirectWithOrderData', this.redirectWithOrderData);

      this.on('saveOrder', this.saveOrder);
      this.on('saveOrderAsQuote', this.saveOrderAsQuote);
      this.on('deleteOrder', this.deleteOrder);
      this.on('cancelOrder', this.cancelOrder);

      this.trigger('needCustomerId', {passive: true});
    });

    this.fireOrderData = function () {
      var errors = {};
      var isDelivery = this.service && this.service.requires_address;

      if (!this.order.ready_time) {
        errors.ready_time = 'missing';
      }

      if (isDelivery && !this.order.destination_id) {
        errors.destination_id = 'missing';
      }
      if (isDelivery && this.isDeliverySearching) {
        errors._deliveryIsSearching = "checking delivery availability";
      }
      if (isDelivery && !this.isDeliveryOK) {
        errors._deliveryNotAvailable = 'not available';
      }

      if (!isDelivery) {
        delete this.order.destination_id;
      }

      this.trigger('orderData', {
        order: this.order,
        restaurant: this.restaurant,
        service: this.service,
        errors: errors
      });
    };

    this.startOrder = function (ev, data) {
      var starting = Order.startOrder(this.order);
      starting = starting.then(function (order) {
        if (order.order_id > 0) {
          this.trigger('dataOrderStarted', order);
        }
        else {
          this.trigger('userActionError', {
            message: 'There was an error starting your order.'
          });
        }
      }.bind(this));
      starting = starting.fail(function (error) {
        var errorObj;
        if (error.responseText) {
          try {
            errorObj = JSON.parse(error.responseText);
          }
          catch (e) {
            errorObj = { error: error.responseText };
          }
        } else {
          errorObj = { error: error.statusText };
        }
        errorObj.status = error.status;
        errorObj.statusText = error.statusText;
        this.trigger('apiError', errorObj);
      }.bind(this));
    };

    this.redirectWithOrderData = function (ev, data) {
      // Copy the order and pre-format ready_date to have the right timezone.
      var o = $.extend({}, this.order);
      var tz, pieces = this.restaurant.timezone.match(/GMT([-+])(\d+)/);
      if (pieces) {
        tz = pieces[1] + (pieces[2].length < 2 ? '0' : '') + pieces[2];
      } else {
        tz = '';
      }
      o.ready_time = moment(o.ready_time).format('YYYY-MM-DDTHH:mm:SS') + tz;
      // Fake a created stamp of now (in restaurant local time).
      o.created = moment(
        this.restaurant.today + ' ' + this.restaurant.localtime,
        'MM/DD/YYYY HH:mm A'
        ).format('YYYY-MM-DDTHH:mm:SS') + tz;

      var orderData = JSON.stringify(o);
      var url = data.redirectUrl;
      $('<form method="POST"></form>')
        .attr('action', url)
        .append($('<input name="order_data">').val(orderData))
        .appendTo('body')
        .submit();
    };


    this.saveOrder = function (ev, data) {
      var customerId = data && data.customerId || this.order.customer_id;
      var orderId = data && data.orderId || this.order.order_id;
      var orderName = data && data.orderName || null;
      Order.saveOrder(customerId, orderId, orderName)
        .then(function () {
          this.trigger('orderSaved');
        }.bind(this))
        .fail(function () {
          this.trigger('userActionError', {
            message: 'There was an error saving your order.'
          });
        }.bind(this));
    };

    this.saveOrderAsQuote = function (ev, data) {
      var customerId = data && data.customerId || this.order.customer_id;
      var orderId = data && data.orderId || this.order.order_id;
      var orderName = data && data.orderName || null;
      var sendToCustomer = data && data.sendToCustomer || null;

      Order.saveOrderAsQuote(customerId, orderId, orderName, sendToCustomer)
        .then(function () {
          this.trigger('orderSavedAsQuote');
        }.bind(this))
        .fail(function () {
          this.trigger('userActionError', {
            message: 'There was an error saving your quote.'
          });
        }.bind(this));
    };

    this.deleteOrder = function (ev, data) {
      Order.deleteOrder(this.order.customer_id, data.orderId)
        .then(function () {
          this.trigger('orderDeleted');
        }.bind(this))
        .fail(function () {
          this.trigger('userActionError', {
            message: 'There was an error deleting your order.'
          });
        }.bind(this));
    };

    this.cancelOrder = function (ev, data) {
      Order.cancelOrder(this.order.customer_id, data.orderId)
        .then(function () {
          this.trigger('orderCanceled', { orderId: data.orderId });
        }.bind(this))
        .fail(function () {
          this.trigger('userActionError', {
            message: 'There was an error canceling your order.'
          });
        }.bind(this));
    };
  }

  return flight.component(orderData);
});

