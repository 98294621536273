
/* START_TEMPLATE */
define('hbs!ndui/nutrition/nutrition_template',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <tr class=\"calories\">\n            <th>Calories</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.calories || (depth0 != null ? depth0.calories : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"calories","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <tr class=\"fat_calories detail\">\n            <th>Calories from Fat</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.fat_calories || (depth0 != null ? depth0.fat_calories : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fat_calories","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <tr class=\"fat\">\n            <th>Total Fat (g)</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.fat || (depth0 != null ? depth0.fat : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fat","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.fat : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_saturated_fat : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_trans_fat : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr class=\"saturated_fat detail\">\n                    <th>Saturated Fat (g)</th>\n                    <td>"
    + escapeExpression(((helper = (helper = helpers.saturated_fat || (depth0 != null ? depth0.saturated_fat : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saturated_fat","hash":{},"data":data}) : helper)))
    + "</td>\n                </tr>\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr class=\"trans_fat detail\">\n                    <th>Trans Fat (g)</th>\n                    <td>"
    + escapeExpression(((helper = (helper = helpers.trans_fat || (depth0 != null ? depth0.trans_fat : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"trans_fat","hash":{},"data":data}) : helper)))
    + "</td>\n                </tr>\n";
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <tr class=\"cholesterol\">\n            <th>Cholesterol (mg)</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.cholesterol || (depth0 != null ? depth0.cholesterol : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cholesterol","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"13":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <tr class=\"sodium\">\n            <th>Sodium (mg)</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.sodium || (depth0 != null ? depth0.sodium : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"sodium","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <tr class=\"carbs\">\n            <th>Carbohydrates (g)</th>\n            <td>"
    + escapeExpression(((helper = (helper = helpers.carbs || (depth0 != null ? depth0.carbs : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"carbs","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.carbs : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_fiber : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_sugars : depth0), {"name":"if","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr class=\"fiber detail\">\n                    <th>Dietary Fiber (g)</th>\n                    <td>"
    + escapeExpression(((helper = (helper = helpers.fiber || (depth0 != null ? depth0.fiber : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fiber","hash":{},"data":data}) : helper)))
    + "</td>\n                </tr>\n";
},"19":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr class=\"sugars detail\">\n                    <th>Sugars (g)</th>\n                    <td>"
    + escapeExpression(((helper = (helper = helpers.sugars || (depth0 != null ? depth0.sugars : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"sugars","hash":{},"data":data}) : helper)))
    + "</td>\n                </tr>\n";
},"21":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tr class=\"protein\">\n        <th>Protein (g)</th>\n        <td>"
    + escapeExpression(((helper = (helper = helpers.protein || (depth0 != null ? depth0.protein : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"protein","hash":{},"data":data}) : helper)))
    + "</td>\n    </tr>\n";
},"23":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"allergens\">\n    Contains:\n    ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.allergens : depth0), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"24":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.index), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)));
},"25":function(depth0,helpers,partials,data) {
  return ", ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<table>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_calories : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_fat_calories : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_fat : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_cholesterol : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_sodium : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_carbs : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.has_protein : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</table>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.allergens : depth0), {"name":"if","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
Handlebars.registerPartial('ndui/nutrition/nutrition_template', t);
return t;
});
/* END_TEMPLATE */
;
