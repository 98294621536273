define('ndui/nutrition/nutrition_totals',['require','flight/lib/component','flight/lib/utils','jquery','flight/lib/logger'],function (require) {
  var defineComponent = require('flight/lib/component');
  var utils = require('flight/lib/utils');
  var $ = require('jquery');
  var withLogging = require('flight/lib/logger');

  function nutritionTotals() {
    var totals = {};

    this.requestNutritionData = function () {
      totals = {};
      this.trigger('data-needs-nutrition');
      this.debouncedNotifyUI();
    };

    this.updateNutritionData = function (e, data) {
      totals._hasNutrition = true;
      if (data.quantity > 0) {
        $.each(data.nutrition, function (k, v) {
          var current = totals[k];
          if (k === 'allergens') {
            if (!current) {
              current = totals.allergens = {};
            }
            $.each(v, function (i, name) { current[name] = true; });
          } else {
            totals[k] = (current || 0) + (v * data.quantity);
          }
        });
      }
      this.debouncedNotifyUI();
    };

    this.notifyUI = function () {
      var data = $.extend({}, totals);

      var allergens = data.allergens;
      delete data.allergens;
      if (allergens && !$.isEmptyObject(allergens)) {
        allergens = $.map(allergens, function (v, k) { return k; }).sort();
        data.allergens = allergens;
      }

      this.trigger('data-nutrition-totals', data);
    };
    this.debouncedNotifyUI = utils.debounce(this.notifyUI, 50);

    this.after('initialize', function () {
      this.on('ui-needs-nutrition-totals', this.requestNutritionData);
      this.on('ui-invalidated-nutrition', this.requestNutritionData);
      this.on('ui-nutrition-data', this.updateNutritionData);
    });
  }

  return defineComponent(nutritionTotals, withLogging);
});

