define('templates/helpers/formatCents',['require','handlebars'],function (require) {
  var Handlebars = require('handlebars');

  function formatCents(cents) {
    return (cents / 100).toFixed(2);
  }

  Handlebars.registerHelper('formatCents', formatCents);
  return formatCents;
});

