define('nd/data/foreign_auth/facebook',['require','flightjs','facebook'],function (require) {
    var flight = require("flightjs");
    var FB = require("facebook");

    function facebookForeignAuthComponent() {
        this.after("initialize", function () {
            this.isEmailScopeDeclined = false;

            this.on("uiNeedForeignAuthProviders", this.handleNeedProvider);
            this.on("uiWantForeignAuthSignIn", this.handleSignIn);

            this.handleNeedProvider();
        });

        this.handleNeedProvider = function handleNeedProvider() {
            this.trigger("dataForeignAuthProviderAvailable", {
                provider: "facebook",
                label: "Login with Facebook",
                image: "fb_login.png"
            });
        };

        this.handleSignIn = function handleSignIn(ev, data) {
            var self = this;

            if (!data || data.provider !== "facebook") {
                return;
            }

            var passive = !!data.passive;

            function triggerFromResponse(response) {
                console.log("Facebook response: %o", response);
                if (response.status === "connected") {
                    FB.api("/me/permissions", function (permResp) {
                        if (!permResp || permResp.error || !permResp.data) {
                            console.log(permResp);
                            self.trigger("dataForeignAuthUnavailable", {
                                provider: "facebook",
                                passive: passive
                            });
                        } else {
                            var isEmailDeclined;
                            permResp.data.forEach(function (p) {
                                if (p.permission === "email") {
                                    isEmailDeclined = p.status === "declined";
                                }
                            })
                            if (isEmailDeclined) {
                                self.isEmailScopeDeclined = true;
                                var m = 'You must allow us to receive your email address to login with Facebook.';
                                self.trigger("dataForeignAuthUnavailable", {
                                    provider: "facebook",
                                    passive: passive,
                                    message: m
                                });
                            } else {
                                self.isEmailScopeDeclined = false;
                                self.trigger("dataForeignAuthTokens", {
                                    provider: "facebook",
                                    tokens: {
                                        access_token: response.authResponse.accessToken,
                                        expires_in: response.authResponse.expiresIn
                                    }
                                });
                            }
                        }
                    });
                } else {
                    console.log(response);  //TODO: What info is here?
                    self.trigger("dataForeignAuthUnavailable", {
                        provider: "facebook",
                        passive: passive
                    });
                }
            }

            FB.getLoginStatus(function (response) {
                var shouldRerequest = !passive && this.isEmailScopeDeclined;

                if (response.status == "connected" && !shouldRerequest) {
                    triggerFromResponse(response);
                }
                else if (!passive) {
                    var options = {
                        scope: "public_profile,email"
                    };
                    if (shouldRerequest) {
                        options.auth_type = "rerequest";
                    }
                    FB.login(function (response) {
                        triggerFromResponse(response);
                    }, options);
                }
                else {
                    triggerFromResponse(response);
                }
            }.bind(this));
        };
    }

    return flight.component(facebookForeignAuthComponent);
});

