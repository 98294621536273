define('nd/ui/forms/require_confirmation',['require','flightjs','jquery-ui'],function (require) {
    var flight = require("flightjs");
    require("jquery-ui");

    function requireConfirmation() {
        this.attributes({
            title: "Please Confirm",
            message: "This action is not reversible.  Are you sure?",
            approveLabel: "Yes",
            cancelLabel: "No",
            iconClass: "ui-icon-alert",
            iconSpanStyle: "float: left; margin: 0 7px 20px 0",
            dialogHeight: 200,
            dialogWidth: 350
        });

        this.after("initialize", function () {
            if (this.$node.is("a")) {
                this.on("click", this.handleClick);
            }
            else {
                this.on("submit", this.handleSubmit);
            }
        });


        this.openDialog = function openDialog() {
            if (!this.$dialog) {
                this.makeDialog();
            }
            this.$dialog.dialog("open");
        };

        this.closeDialog = function closeDialog() {
            this.$dialog.dialog("close");
        };

        this.makeDialog = function buildDialog() {
            var buttons = {};
            buttons[this.attr.approveLabel] = this.handleApprove.bind(this);
            buttons[this.attr.cancelLabel] = this.handleCancel.bind(this);

            var $p = $("<p></p>").text(this.attr.message);
            if (this.attr.iconClass) {
                $p.prepend(
                    $("<span></span>")
                    .addClass("ui-icon")
                    .addClass(this.attr.iconClass)
                    .css(this.attr.iconSpanStyle));
            }
            this.$dialog = $("<div></div>").append($p).dialog({
                title: this.attr.title,
                height: this.attr.dialogHeight,
                width: this.attr.dialogWidth,
                resizable: false,
                autoOpen: false,
                modal: true,
                buttons: buttons
            });
        };


        this.handleSubmit = function handleSubmit(ev) {
            var hasValid = this.$node.valid;
            if (!hasValid || this.$node.valid()) {
                if (!this.approved) {
                    ev.preventDefault();
                    ev.stopImmediatePropagation();
                    this.openDialog();
                }
            }
            this.approved = false;
        };

        this.handleClick = function handleClick(ev) {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            this.openDialog();
        }

        this.handleApprove = function handleApprove(ev) {
            ev.preventDefault();
            this.approved = true;
            this.closeDialog();
            if (this.$node.is("a")) {
                window.location = this.$node.attr("href");
            }
            else {
                this.$node.submit();
            }
        };

        this.handleCancel = function handleCancel(ev) {
            ev.preventDefault();
            this.closeDialog();
        };
    }

    return flight.component(requireConfirmation);
});

