define('nddata/service',['require','flight/lib/component'],function (require) {
  var defineComponent = require('flight/lib/component');


  function serviceData() {
    this.defaultAttrs({
    });

    this.after('initialize', function () {
      this.restaurant = null;
      this.service = null;

      this.on('selectService', function (ev, data) {
        if (data && data.service) {
          this.service = data.service;
        }
        else if (this.restaurant) {
          var r = this.restaurant;
          if (!r || !r.services || r.services.length === 0) {
            this.service = null;
          }
          else {
            var services = this.restaurant.services;
            var matching = [];
            var serviceTypeId = data.serviceTypeId;
            if (!serviceTypeId) {
              serviceTypeId = this.service && this.service.service_type_id;
            }
            if (serviceTypeId) {
              matching = services.filter(function (s) {
                return s.service_type_id == serviceTypeId;
              });
            }
            // this.service = (matching.length > 0) ? matching[0] : services[0];
            this.service = (matching.length == 1) ? matching[0] : null;
          }
        }
        else {
          this.service = null;
        }
        this.trigger('needServiceData');
      });

      this.on('needServiceData', function () {
        this.trigger('serviceData', {service: this.service});
      });

      this.on(document, 'restaurantData', function (ev, data) {
        this.restaurant = data.restaurant;
        var serviceTypeId = this.service && this.service.service_type_id;
        this.trigger('selectService', {serviceTypeId: serviceTypeId});
      });
    });
  }

  return defineComponent(serviceData);
});

