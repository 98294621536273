define('nd/ui/payments/google_wallet_ui',['require','flightjs','jquery','handlebars'],function(require) {
    var flight = require("flightjs");
    var $ = require("jquery");
    var Handlebars = require("handlebars");

    return flight.component(googleWalletUI);

    function googleWalletUI() {
        this.attributes({
            orderDataSelector: "#main-content-placeholder",
            paymentCompleteUrl: "/mp/patrons/process_order_s2?orderid={{order.orderId}}&mailreceipt=1",
            timeBumpUrl: "/mp/patrons/cart/update_action?action=checkout"
        });

        this.after("initialize", function() {
            this.on(document, "dataGoogleWalletFullWalletRequest",
                    this.handlePaymentReady);
            this.on(document, "dataGoogleWalletPaymentComplete",
                    this.handlePaymentComplete);
            this.on(document, "dataGoogleWalletOrderNeedsMoreTime",
                    this.handleTimeBump);
            this.on(document, "dataGoogleSignInResult",
                    this.createWalletButton);
        });

        this.createWalletButton = function() {
            if (!window.google) {
                return;
            }

            function readyCb(resp) {
                console.log("Wallet button ready: %o", resp);
                if (resp.status === "SUCCESS") {
                    this.insertWalletButton(resp.walletButtonElement);
                }
            }

            function successCb(resp) {
                console.log("Wallet button success: %o", resp);
                var orderId = $(this.attr.orderDataSelector).data("orderId");
                this.trigger("uiWantsGoogleWalletPayment", {
                    orderId: orderId,
                    jwt: resp.jwt,
                    response: resp.response
                });
            }

            function failureCb(resp) {
                console.log("Wallet button failure: %o", resp);
            }

            var jwt = this.$node.data("jwt");
            console.log("Calling google.wallet.online.createWalletButton: %o", [{
                jwt: jwt,
                height: "32",
                width: "max"
            }]);
            google.wallet.online.createWalletButton({
                jwt: jwt,
                ready: readyCb.bind(this),
                success: successCb.bind(this),
                failure: failureCb.bind(this),
                height: "32",
                width: "max"
            });
        };

        this.insertWalletButton = function(walletButtonElement) {
            this.$node.html(walletButtonElement);
        };

        this.handlePaymentReady = function(ev, data) {
            this.askForConfirmation(data);
        };

        this.handlePaymentComplete = function(ev, data) {
            var template = Handlebars.compile(this.attr.paymentCompleteUrl);
            var url = template(data);
            window.location.href = url;
        };

        this.handleTimeBump = function (ev, data) {
            var template = Handlebars.compile(this.attr.timeBumpUrl);
            var url = template(data);
            window.location.href = url;
        };

        this.askForConfirmation = function(data) {
            this.trigger("uiConfirmedGoogleWalletPayment", data);
        };

    }
});

