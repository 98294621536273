define('nd/ui/auth/with_foreign_auth',['require','lodash'],function (require) {
    var _ = require("lodash");

    function withForeignAuth() {
        this.attributes({
            foreignAuthSignInSelector: ".js-foreign-auth-sign-in"
        });

        this.after("initialize", function () {
            this.foreignAuthProviders = {};
            this.on(
                document,
                "dataForeignAuthProviderAvailable",
                this.handleForeignAuthProviderAvailable
            );
            this.trigger("uiNeedForeignAuthProviders");

            this.on("click", {
                foreignAuthSignInSelector: this.handleForeignAuth
            });
        });

        this.getForeignAuthProviders = function () {
            var providers = _.values(this.foreignAuthProviders);
            providers.sort(function (a, b) {
                return (a.priority || 50) - (b.priority || 50);
            });
            return providers;
        };

        this.handleForeignAuthProviderAvailable = function (ev, data) {
            this.foreignAuthProviders[data.provider] = data;
            this.foreignAuthProvidersUpdated(this.getForeignAuthProviders());
        };

        this.handleForeignAuth = function handleForeignAuth(ev) {
            ev.preventDefault();

            var link = $(ev.target).closest(this.attr.foreignAuthSignInSelector);
            var provider = link.data("provider");
            this.trigger("uiWantForeignAuthSignIn", { provider: provider });
        };

        this.announceProvidersWereRendered = function (providers) {
            providers.forEach(function (provider) {
                this.trigger("uiRenderedForeignAuthProvider", provider);
            }.bind(this));
        };
    }

    return withForeignAuth;
});

