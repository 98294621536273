/*global console */
define('nd/data/loyalty',['require','flightjs','jquery','ndlib/api'],function (require) {
  var flight = require("flightjs");
  var $ = require("jquery");

  var ndXtal = require("ndlib/api");

  function loyaltyComponent() {
    this.attributes({
      initialData: false
    });

    this.after("initialize", function () {
      this.fetching = null;
      this.customerId = null;
      this.initialData = this.attr.initialData;

      this.on("uiNeedLoyaltyData", function (ev, data) {
        var customer = data && data.customer;
        this.customerId = customer && !customer.isGuest && customer.customersid;

        if (this.initialData) {
          this.fetching = $.when(this.initialData);
          this.initialData = null;
        } else {
          this.fetching = null;
        }

        this.fetch();
      });

    });

    this.fetch = function () {
      var fetching;

      if (!this.customerId) {
        this.trigger("dataLoyalty", {});
        this.fetching = null;
        return;
      }

      if (this.fetching) {
        fetching = this.fetching;
      } else {
        fetching = this.fetching = ndXtal.getAvailableCoupons({
          customerId: this.customerId
        });
      }

      fetching.then(function (loyalty) {
        if (this.fetching !== fetching) {
          // Bail if another fetch was started while we were waiting.
          return;
        }

        this.trigger("dataLoyalty", loyalty);

        if (!loyalty.isComplete && loyalty.isComplete != null) {
          // The data is incomplete.  Wait and fetch again.
          this.fetching = null;
          setTimeout(function () {
            if (!this.fetching) { this.fetch(); }
          }.bind(this), 2500);
        }
      }.bind(this));
    };
  }

  return flight.component(loyaltyComponent);
});

