requirejs.config({
  packages: [{
    name: "cs",
    location: "bower_components/require-cs",
    main: "cs"
  }, {
    name: "coffee-script",
    location: "bower_components/coffeescript",
    main: "extras/coffee-script"
  }],
  paths: {
    "css": "bower_components/require-css/css",
    "domReady": "bower_components/requirejs-domready/domReady",
    "es5-shim": "bower_components/es5-shim/es5-shim",
    "es5-sham": "bower_components/es5-shim/es5-sham",
    "flight": "bower_components/flight",
    "griddle-react": "node_modules/griddle-react/build/griddle",
    "hbs": "bower_components/require-handlebars-plugin/hbs",
    "hgn": "bower_components/requirejs-hogan-plugin/hgn",
    "hogan": "bower_components/requirejs-hogan-plugin/hogan",
    "jquery": "node_modules/jquery/dist/jquery",
    "jquery-ui": "bower_components/jquery-ui/ui/jquery-ui",
    "jquery.validate": "bower_components/jquery-validate/dist/jquery.validate",
    "jquery.validate.additional-methods": "bower_components/jquery-validate/dist/additional-methods",
    "jquery.validate.state-code": "/nd/views/jquery.validate.state-code",
    "jsx": "bower_components/requirejs-react-jsx/jsx",
    "JSXTransformer": "bower_components/react/JSXTransformer",
    "lodash": "bower_components/lodash/dist/lodash",
    "microplugin": "bower_components/microplugin/src/microplugin",
    "moment": "bower_components/moment/moment",
    "react": "bower_components/react/react-with-addons",
    "rxjs": "node_modules/@reactivex/rxjs/dist/global/Rx.min",
    "selectize": "bower_components/selectize/dist/js/selectize",
    "selectize-css": "bower_components/selectize/dist/css/selectize",
    "sifter": "bower_components/sifter/sifter",
    "text": "bower_components/requirejs-text/text",
    "underscore": "bower_components/underscore/underscore",
    "requirejs": "bower_components/requirejs/require",
    "bootstrap-js": "contrib/bootstrap/js/bootstrap.min",
    "bootstrap-css": "contrib/bootstrap/css/bootstrap.min",
    "bootstrap-multi-css": "contrib/bootstrap-multiselect/css/bootstrap-multiselect",
    "bootstrap-multi-js": "contrib/bootstrap-multiselect/js/bootstrap-multiselect",
    "bootstrap4-js": "contrib/bootstrap4-js.min",
  },
  map: {
    "*": {
      "flightjs": "flight-with-jquery",
      "flight/lib/index": "flight/index",
      "handlebars": "hbs/handlebars",
      "@reactivex/rxjs": "rxjs"
    },
    "griddle-react": {
      "React": "react",
      "_": "underscore"
    }
  },
  shim: {
    "jquery-ui": {
      deps: ["jquery"]
    },
    "jquery.validate": {
      deps: ["jquery"],
      exports: "jQuery.fn.validate"
    },
    "jquery.validate.additional-methods": {
      deps: ["jquery.validate"]
    },
    "react": {
      "exports": "React"
    },
    "JSXTransformer": "JSXTransformer"
  },
  hbs: {
    disableI18n: true
  },
  jsx: {
    fileExtension: ".jsx",
    transformOptions: {
      harmony: true
    },
    usePragma: false
  }
});

// Based on https://developers.facebook.com/docs/javascript/howto/requirejs
requirejs.config({
  shim: {
    "facebook-sdk": {
      exports: "FB"
    }
  },
  paths: {
    "facebook-sdk": "//connect.facebook.net/en_US/all"
    //"facebook-sdk": "//connect.facebook.net/en_US/sdk/debug"
  }
});

define("config", function(){});

