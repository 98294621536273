
// Generated by CoffeeScript 1.7.1
(function() {
  define('cs/cs!nd/ui/ordering/delivery/delivery_address',['require','flightjs','jquery','jquery.validate.additional-methods','jquery.validate.state-code'],function(require) {
    var $, deliveryAddress, flight;
    flight = require("flightjs");
    $ = require("jquery");
    require("jquery.validate.additional-methods");
    require("jquery.validate.state-code");
    deliveryAddress = function() {
      this.attributes({
        addressEntrySelector: ".js-address-entry",
        saveButtonSelector: ".js-save-button",
        formSelector: "form",
        addressListSelector: "[name='address_id']",
        postalCodeSelector: "[name='postalCode']",
        address1Selector: "[name='address1']",
        address2Selector: "[name='address2']",
        address3Selector: "[name='address3']",
        citySelector: "[name='city']",
        stateSelector: "[name='state']",
        phoneSelector: "[name='phone']",
        descriptionSelector: "[name='description']"
      });
      this.after("initialize", function() {
        this.on("submit", {
          formSelector: this.handleSave
        });
        this.on("change", this.handleChange);
        this.on(document, "dataCustomerAddresses", this.handleCustomerAddresses);
        this.on(document, "dataDeliveryAddressSuggestion", this.handleSuggestion);
        this.on(document, "orderData", this.onOrderData);
        this.select("formSelector").validate({
          rules: {
            postalCode: {
              required: true,
              zipcodeUS: true
            },
            address1: {
              required: true
            },
            city: {
              required: true
            },
            state: {
              required: true,
              stateCode: true
            },
            phone: {
              required: true,
              phoneUS: true
            }
          }
        });
        this.trigger("uiNeedCustomerAddresses");
        return null;
      });
      this.handleSave = function(ev) {
        ev.preventDefault();
        this.trigger("uiSaveDeliveryAddress", {
          address: this.getAddress()
        });
        return null;
      };
      this.handleChange = function(ev, data) {
        if ($(ev.target).is(this.attr.addressListSelector)) {
          this.handleAddressSelection(ev, data);
        } else {
          this.trigger("uiPartialDeliveryAddress", {
            address: this.getAddress()
          });
        }
        return null;
      };
      this.handleAddressSelection = function() {
        var destinationId;
        destinationId = parseInt(this.select('addressListSelector').val(), 10);
        return this.trigger("uiSelectDeliveryAddress", {
          destinationId: destinationId
        });
      };
      this.handleSuggestion = function(ev, data) {
        var _ref;
        this.updateAddress((_ref = data.address) != null ? _ref : {});
        return null;
      };
      this.handleCustomerAddresses = function(ev, data) {
        var _ref;
        this.addresses = (_ref = data != null ? data.addresses : void 0) != null ? _ref : [];
        this.updateAddressList();
        return null;
      };
      this.onOrderData = function(ev, data) {
        var $addressEntry, destinationId, service, _ref;
        service = data != null ? data.service : void 0;
        this.needsAddress = (service != null ? service.requires_address : void 0) && !(service != null ? service.redirect_url : void 0);
        if (this.needsAddress) {
          this.$node.show();
        } else {
          this.$node.hide();
        }
        destinationId = (data != null ? (_ref = data.order) != null ? _ref.destination_id : void 0 : void 0) || "";
        $addressEntry = this.select('addressEntrySelector');
        this.select("addressListSelector").val(destinationId).dTselect();
        if (destinationId) {
          $addressEntry.hide();
        } else {
          $addressEntry.show();
        }
        return null;
      };
      this.getAddress = function() {
        var address, field, _i, _len, _ref;
        address = {};
        _ref = this.select("formSelector").serializeArray();
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          field = _ref[_i];
          address[field.name] = field.value.trim();
        }
        return address;
      };
      this.updateAddress = function(address, overwrite) {
        var upd;
        upd = (function(_this) {
          return function(selector, field) {
            var $el, val;
            $el = _this.select(selector);
            val = address[field] || "";
            if (overwrite || !$el.val()) {
              return $el.val(val);
            }
          };
        })(this);
        upd("postalCodeSelector", "postalCode");
        upd("address1Selector", "address1");
        upd("address2Selector", "address2");
        upd("address3Selector", "address3");
        upd("citySelector", "city");
        upd("stateSelector", "state");
        upd("phoneSelector", "phone");
        return null;
      };
      this.updateAddressList = function() {
        var addr, addressAsOption, addressList, _i, _len, _ref;
        addressAsOption = function(addr) {
          var desc, p;
          if (addr.description) {
            desc = addr.description;
          } else {
            desc = ((function() {
              var _i, _len, _ref, _results;
              _ref = [addr.address1, addr.address2, addr.city, addr.state_code, addr.postal_code];
              _results = [];
              for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                p = _ref[_i];
                if (p) {
                  _results.push(p);
                }
              }
              return _results;
            })()).join(", ");
          }
          return $("<option />").val(addr.addressid).text(desc);
        };
        addressList = this.select("addressListSelector");
        addressList.find('option').slice(1).remove();
        _ref = this.addresses;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          addr = _ref[_i];
          addressList.append(addressAsOption(addr));
        }
        return null;
      };
      return this.after("updateAddressList", function() {
        this.select("addressListSelector").dTselect();
        return null;
      });
    };
    return flight.component(deliveryAddress);
  });

}).call(this);

