define('ndui/nutrition/nutrition_source',['require','flight/lib/component','./with_get_quantity','jquery'],function (require) {
  var defineComponent = require('flight/lib/component');
  var withGetQuantity = require('./with_get_quantity');
  var $ = require('jquery');

  function nutritionInputSource() {
    this.defaultAttrs({
      nutritionSelector: '[data-nutrition]',
      selectSelector: 'select'
    });

    this.isValidNutrition = function (nutrition) {
      var n = $.extend({}, nutrition);
      var a = n.allergens;
      delete n.allergens;
      return !$.isEmptyObject(n) || (a && a.length > 0);
    };

    this.sendNutritionData = function () {
      this.select('nutritionSelector').each(function (i, e) {
        var $e = $(e);
        var nutrition = $e.data('nutrition');
        if (this.isValidNutrition(nutrition)) {
          var quantity = this.getQuantity($e);
          this.trigger($e, 'ui-nutrition-data', {
            nutrition: nutrition,
            quantity: quantity
          });
        }
      }.bind(this));
    };

    this.invalidateNutritonTotals = function () {
      this.trigger('ui-invalidated-nutrition');
    };

    this.after('initialize', function () {
      this.on('data-needs-nutrition', this.sendNutritionData);
      this.on('change', {
        'nutritionSelector': this.invalidateNutritonTotals,
        'selectSelector': this.invalidateNutritonTotals
      });
    });
  }

  return defineComponent(nutritionInputSource, withGetQuantity);
});

