define('nd/data/foreign_auth/google',['require','flightjs','jquery','google'],function(require) {
    var flight = require("flightjs");
    var $ = require("jquery");
    var google = require("google");

    var googleWallet = window.google && window.google.wallet;

    return flight.component(googleForeignAuthComponent);

    function googleForeignAuthComponent() {
        this.attributes({
            signOut: false
        });

        this.after("initialize", function () {
            this.wantsSignOut = this.attr.signOut;

            this.on("uiNeedForeignAuthProviders", this.handleNeedProvider);
            this.on("uiWantForeignAuthSignIn", this.handleSignIn);
            this.on("uiRenderedForeignAuthProvider", this.handleRendered);
            this.on("dataGoogleSignInResult", this.handleSignInResult);

            var last_access_token = {}; // Something always unique
            window.ndForeignAuthGoogleCallback = function (auth) {
                console.log("Google auth called back: %o", auth);

                var announceSignIn = function announceSignIn() {
                    // This setTimeout is here to make sure all components have
                    // had a chance to listen first.  It may not be needed
                    // depending on whether Google ever calls the callback
                    // directly.
                    setTimeout(function () {
                        this.trigger("dataGoogleSignInResult", {
                            access_token: auth.access_token,
                            expires_in: auth.expires_in,
                            signed_in: auth.status && auth.status.signed_in
                        });
                    }.bind(this), 0);
                }.bind(this);

                if (this.wantsSignOut) {
                    console.log("Calling gapi.auth.signOut: %o", []);
                    google.gapi.auth.signOut();
                    this.wantsSignOut = false;
                }
                else if (auth.access_token === last_access_token) {
                    console.log("access_token hasn't changed, ignoring");
                }
                else {
                    last_access_token = auth.access_token;
                    if (googleWallet) {
                        if (auth.access_token) {
                            console.log("Calling google.wallet.online.setAccessToken: %o",
                                [auth.access_token]);
                            googleWallet.online.setAccessToken(auth.access_token);
                            announceSignIn();
                        } else {
                            console.log("Calling google.wallet.online.authorize: %o", [{
                                clientId: google.clientId
                            }]);
                            googleWallet.online.authorize({
                                clientId: google.clientId,
                                callback: function (r) {
                                    console.log("Google returned: %o", r);
                                    announceSignIn();
                                }.bind(this)
                            });
                        }
                    } else {
                        announceSignIn();
                    }
                }
            }.bind(this);

            this.handleNeedProvider();

            setTimeout(function () {
                if (this.isSignInButtonRendered) {
                    return;
                }
                // Always render the Sign In button, even if it isn't
                // going to be displayed to the user.  A lot of logic is
                // triggered by rendering the button that doesn't seem to
                // be available directly in the API.
                var button = $('#foreign-auth-google-button');
                if (!button.length) {
                    console.log('Creating hidden Google sign-in button');
                    button = $("<div id='foreign-auth-google-button'></div>");
                    $("<div style='display: none'></div>")
                        .append(button)
                        .appendTo("body");
                } else {
                    console.log('Rendering Google sign-in button from timeout');
                }
                this.renderSignInButton();
            }.bind(this), 250);
        });

        this.handleNeedProvider = function () {
            this.trigger("dataForeignAuthProviderAvailable", {
                provider: "google",
                label: "Sign in with Google",
                divId: "foreign-auth-google-button",
                priority: 10
            });
        };

        this.handleRendered = function (ev, data) {
            if (data.provider !== "google") {
                return;
            }
            this.renderSignInButton();
        };

        this.renderSignInButton = function () {
            var scope = "profile email";
            if (google.useWallet === "production") {
                scope += " https://www.googleapis.com/auth/payments.make_payments";
            } else if (google.useWallet === "sandbox") {
                scope += " https://www.googleapis.com/auth/paymentssandbox.make_payments";
            }
            $('#foreign-auth-google-button').empty();
            console.log("Calling gapi.signin.render: %o", ["foreign-auth-google-button", {
                callback: "ndForeignAuthGoogleCallback",
                clientid: google.clientId,
                scope: scope,
                cookiepolicy: "single_host_origin"
            }]);
            google.gapi.signin.render("foreign-auth-google-button", {
                callback: "ndForeignAuthGoogleCallback",
                clientid: google.clientId,
                scope: scope,
                cookiepolicy: "single_host_origin"
            });
            this.isSignInButtonRendered = true;
        };

        this.handleSignIn = function () {
            // The Google rendered button handles the sign-in process.
        };

        this.handleSignInResult = function (ev, data) {
            if (data.signed_in) {
                this.trigger("dataForeignAuthTokens", {
                    provider: "google",
                    tokens: {
                        access_token: data.access_token,
                        expires_in: data.expires_in
                    }
                });
            } else {
                this.trigger("dataForeignAuthUnavailable", {
                    provider: "google",
                    passive: false
                });
            }
        };
    }
});

