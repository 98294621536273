define('templates/helpers/formatFixed',['require','handlebars'],function (require) {
  var Handlebars = require('handlebars');

  function formatFixed(num, prec) {
    return num.toFixed(prec);
  }

  Handlebars.registerHelper('formatFixed', formatFixed);
  return formatFixed;
});

