define('nd/data/payments/google_wallet',['require','flightjs','jquery','underscore','google'],function(require) {
    var flight = require("flightjs");
    var $ = require("jquery");
    var _ = require("underscore");
    var googleConf = require("google");

    return flight.component(googleWalletComponent);

    function googleWalletComponent() {
        this.after("initialize", function() {
            if (googleConf.useWallet) {
                this.on("dataGoogleWalletPaymentStatus",
                        this.handlePaymentStatus);
                this.on("uiWantsGoogleWalletPayment",
                        this.handleWantsPayment);
                this.on("uiWantsChangedGoogleWalletPayment",
                        this.handleWantsChange);
                this.on("uiConfirmedGoogleWalletPayment",
                        this.handleConfirmedPayment);
            }
            return null;
        });

        this.handleWantsPayment = function(ev, data) {
            var maskedWalletResponse =
                data.response ? data.response.response : null;

            $.ajax({
                url: "/mp/ndXTAL/apply_masked_wallet_response",
                type: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    order_id: data.orderId,
                    jwt: data.jwt
                }),
                processData: false
            })
                .then(checkForTimeBump.bind(this))
                .then(failOnApiErrors, cleanAjaxErrors)
                .then(function(details) {
                    if (details.google_wallet) {
                        details.google_wallet.masked_wallet_response =
                            maskedWalletResponse;
                        this.trigger("dataGoogleWalletFullWalletRequest",
                                     details);
                    }
                    return details;
                }.bind(this))
                .fail(this.showError.bind(this));
        };

        this.handleWantsChange = function(ev, data) {
            console.log("Calling google.wallet.online.changeMaskedWallet: %o", [{
                jwt: data.google_wallet.masked_wallet_request_jwt,
            }]);
            google.wallet.online.changeMaskedWallet({
                jwt: data.google_wallet.masked_wallet_request_jwt,
                success: function (r) {
                    console.log("Google returned %o", r);
                    r.orderId = data.order.orderId;
                    this.trigger("uiWantsGoogleWalletPayment", r);
                }.bind(this),
                failure: this.showError.bind(this)
            });
        };

        this.handleConfirmedPayment = function(ev, data) {
            function successCb(r) {
                console.log("Google returned %o", r);
                $.ajax({
                    url: "/mp/ndXTAL/apply_full_wallet_response",
                    type: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify({
                        order_id: data.order.orderId,
                        jwt: r.jwt,
                        pan: r.pan,
                        cvn: r.cvn,
                        preserve_session: true
                    }),
                    processData: false
                })
                    .then(checkForTimeBump.bind(this))
                    .then(failOnApiErrors, cleanAjaxErrors)
                    .then(function(details) {
                        details.googleTransactionId =
                            r.response.response.googleTransactionId;
                        this.trigger("dataGoogleWalletPaymentStatus", details);
                        return details;
                    }.bind(this))
                    .fail(this.showError.bind(this));
            }
            console.log("Calling google.wallet.online.requestFullWallet: %o", [{
                jwt: data.google_wallet.full_wallet_request_jwt,
            }]);
            google.wallet.online.requestFullWallet({
                jwt: data.google_wallet.full_wallet_request_jwt,
                success: successCb.bind(this),
                failure: this.showError.bind(this)
            });
        };

        this.handlePaymentStatus = function(ev, data) {
            function checkStatus() {
                $.ajax({
                    url: "/mp/ndXTAL/checkPayment_JSON",
                    type: "POST",
                    data: {
                        payProcId: data.pendingPayment.payProcId,
                        token: JSON.stringify(data.pendingPayment.token),
                        wallet_transaction_id: JSON.stringify(
                            data.googleTransactionId),
                        preserve_session: "true"
                    }
                })
                    .then(checkForTimeBump.bind(this))
                    .then(failOnApiErrors, cleanAjaxErrors)
                    .then(function(details) {
                        details.googleTransactionId = data.googleTransactionId;
                        this.trigger("dataGoogleWalletPaymentStatus", details);
                        return details;
                    }.bind(this))
                    .fail(this.showError.bind(this));
            }
            if (data.pendingPayment) {
                setTimeout(checkStatus.bind(this), 2000);
                return;
            }
            console.log("Calling google.wallet.online.notifyTransactionStatus: %o", [{
                jwt: data.wallet_status_notification_jwt,
            }]);
            google.wallet.online.notifyTransactionStatus({
                jwt: data.wallet_status_notification_jwt,
                success: function (r) {
                    console.log("Google returned: %o", r);
                }.bind(this),
                failure: this.showError.bind(this)
            });
            this.trigger("dataGoogleWalletPaymentComplete", data);
        };

        this.showError = function (error) {
            console.log("showError: %o", error);
            var msg = error.message || error.details;
            if (!error.hide_error) {
                this.trigger("userActionError", { message: error.message });
            }
        };
    }


    function checkForTimeBump(ret) {
        if (ret.error && ret.errors && ret.errors.need_more_time) {
            this.trigger("dataGoogleWalletOrderNeedsMoreTime", ret);
        }
        return ret;
    }

    function failOnApiErrors(ret) {
        var err;
        if (ret.error) {
            err = new Error(ret.error);
        }
        if (ret.errors) {
            var msgs = $.map(ret.errors, function (v) { return v; });
            err = new Error(msgs.join(", "));
        }
        if (err) {
            err.hide_error = ret.hide_error;
            return $.Deferred().reject(err);
        }
        return ret;
    }

    function cleanAjaxErrors(resp) {
        var ret = resp.responseJSON;
        if (ret.error || ret.errors) {
            return failOnApiErrors(ret);
        } else {
            return $.Deferred().reject(
                new Error("Internal error: " + resp.statusText));
        }
    }
});

