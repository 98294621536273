define('ndmodel/order',['require','moment','ndlib/api'],function (require) {
  var moment = require('moment');

  var ndApi = require('ndlib/api');


  function Order() {
  }


  Order.startOrder = function (order) {
    var rt = moment(order.ready_time);
    var asap_order = order.is_asap;

    return ndApi.startOrder({
      restaurantId: order.restaurant_id,
      customerId: order.customer_id,
      serviceTypeId: order.servicetype_id,
      year: rt.year(),
      month: rt.month() + 1,
      day: rt.date(),
      hour: rt.hour(),
      minute: rt.minute(),
      destination_id: order.destination_id,
      baseOrderId: order.base_order_id,
      is_asap: asap_order
    })
    .then(function (result) {
      if (result.length) {
        order.order_id = result[0];
        order.order_changes = result[1];
      } else {
        order.order_id = result;
      }
      return order;
    });
  };


  Order.saveOrder = function (customerId, orderId, orderName) {
    return ndApi.addSavedOrder({
      customerId: customerId,
      orderId: orderId,
      orderName: orderName
    });
  };

  Order.saveOrderAsQuote = function (customerId, orderId, orderName, sendToCustomer) {
    return ndApi.saveOrderAsQuote({
      customerId: customerId,
      orderId: orderId,
      orderName: orderName,
      emailToCustomer: !!sendToCustomer
    });
  };

  Order.deleteOrder = function (customerId, orderId) {
    return ndApi.deleteSavedOrder({
      customerId: customerId,
      orderId: orderId
    });
  };

  Order.cancelOrder = function (customerId, orderId) {
    return ndApi.cancelOrder({
      customerId: customerId,
      orderId: orderId
    });
  };


  Order.getCustomerFavorites = function (customerId) {
    return ndApi.getCustomerFavorites({
      customerId: customerId
    });
  };


  Order.getCustomerOrders = function (customerId, includeItems, limit) {
    return ndApi.getCustomerOrders({
      customerId: customerId,
      includeItems: includeItems,
      limit: limit
    });
  };


  Order.getSavedOrders = function (customerId, includeItems, limit) {
    return ndApi.getSavedOrders({
      customerId: customerId,
      includeItems: includeItems,
      limit: limit
    });
  };


  return Order;
});

