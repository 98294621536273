define('ndui/nutrition/nutrition_matrix_source',['require','flight/lib/component','./with_get_quantity','jquery'],function (require) {
  var defineComponent = require('flight/lib/component');
  var withGetQuantity = require('./with_get_quantity');
  var $ = require('jquery');

  function nutritionMatrixSource() {
    this.defaultAttrs({
      nutritionMatrixSelector: '[data-nutrition-matrix]',
      sizeSelector: 'input[name="sizeid"]',
      styleSelector: 'input[name="styleid"]'
    });

    this.sendNutritionData = function () {
      var sizeid = this.select('sizeSelector').filter(':checked').val();
      var styleid = this.select('styleSelector').filter(':checked').val();
      var sizeKey = sizeid || 'None';
      var fullKey = sizeKey + '/' + (styleid || 'None');

      this.select('nutritionMatrixSelector').each(function (i, e) {
        var $e = $(e);
        var nutritionMatrix = $e.data('nutritionMatrix');
        if (nutritionMatrix && !$.isEmptyObject(nutritionMatrix)) {
          var nutrition = nutritionMatrix[fullKey];
          if (!nutrition) {
            nutrition = nutritionMatrix[sizeKey];
          }
          if (nutrition) {
            var qty;
            if ($e.is('div')) {
              // A nutrition matrix is set on a hidden div to give the info
              // for the main item.  getQuantity ignores hidden elements, so
              // force all divs should be counted with quantity 1. This could
              // break if the rendering of things with data-nutrition-matrix
              // gets fancier.
              qty = 1;
            } else {
              qty = this.getQuantity($e);
            }
            this.trigger('ui-nutrition-data', {
              nutrition: nutrition,
              quantity: qty
            });
          } else {
            // Still fire an event to let folks know we have some nutrition
            // information available.
            this.trigger('ui-nutrition-data', {
              quantity: 0
            });
          }
        }
      }.bind(this));
    };

    this.invalidateNutritonTotals = function () {
      this.trigger('ui-invalidated-nutrition');
    };

    this.after('initialize', function () {
      this.on('data-needs-nutrition', this.sendNutritionData);
      this.on('change', {
        'sizeSelector': this.invalidateNutritonTotals,
        'styleSelector': this.invalidateNutritonTotals,
        'nutritionMatrixSelector': this.invalidateNutritonTotals
      });
    });
  }

  return defineComponent(nutritionMatrixSource, withGetQuantity);
});

