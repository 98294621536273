define('ndlib/api',['require','exports','module','lodash','jquery'],function (require, exports) {
  var _ = require('lodash');
  var $ = require('jquery');

  function callMethod(requestFactory, data) {
    return $.ajax(requestFactory(data));
  }

  function makeTypicalRequest(method, data) {
    var munged = {};
    _.each(data, function (v, k) {
      if (v != null) {
        munged[k] = JSON.stringify(v);
      }
    });

    return {
      url: '/mp/ndXTAL/' + method,
      type: 'POST',
      data: munged
    };
  }

  function makeNewStyleRequest(method, data) {
    return {
      url: '/mp/ndXTAL/' + method,
      type: 'POST',
      data: JSON.stringify(data),
      processData: false,
      contentType: 'application/json',
      dataType: 'json'
    };
  }

  function declare(name, requestFactory) {
    var factoryName =
      'make' + name.charAt(0).toUpperCase() + name.slice(1) + 'Request';
    exports[factoryName] = requestFactory;
    exports[name] = _.partial(callMethod, requestFactory);
  }


  //
  // Restaurant searching
  //

  declare('searchByGeoCode', function (params) {
    if (params.distance == null) {
      params.distance = 20;
    }
    return makeTypicalRequest('searchByGeoCode_JSON', {
      customerId: params.customerId,
      latitude: params.latitude,
      longitude: params.longitude,
      distanceInMiles: params.distance
    });
  });

  declare('searchDeliveryRestaurants', function (params) {
    return makeTypicalRequest('searchDeliveryRestaurants_JSON', {
      // customerId: params.customerId,
      stNum: params.streetNumber,
      stAddr: params.address1,
      city: params.city,
      state: params.state,
      zipcode: params.postalCode,
      latitude: params.latitude,
      longitude: params.longitude,
      timeStamp: params.timeStamp,
      serviceHours: params.serviceHours,
      locationId: params.locationId
    });
  });

  declare('searchPickupRestaurants', function (params) {
    return makeTypicalRequest('searchPickupRestaurants_JSON', {
      customerId: params.customerId,
      latitude: params.latitude,
      longitude: params.longitude,
      stNum: params.streetNumber,
      stAddr: params.address1,
      city: params.city,
      state: params.state,
      zipcode: params.postalCode,
      distanceInMiles: params.distanceInMiles,
      limit: params.limit,
      timeStamp: params.timeStamp,
      serviceHours: params.serviceHours,
      notActive: params.notActive
    });
  });

  declare('searchByStoreId', function (params) {
    return makeTypicalRequest('searchByStoreId_JSON', {
      storeId: params.storeId,
      serviceHours: params.serviceHours
    });
  });

  declare('searchFavoriteRestaurants', function (params) {
    return makeTypicalRequest('searchFavoriteRestaurants_JSON', {
      customerId: params.customerId,
      serviceHours: params.serviceHours
    });
  });

  declare('getLocationInformation', function (params) {
    return makeTypicalRequest('getLocationInformation_JSON', {
      restaurantId: params.restaurantId,
      serviceTypeId: params.serviceTypeId,
      serviceHours: params.serviceHours
    });
  });



  //
  // Customer functions
  //


  declare('login', function (params) {
    return makeTypicalRequest('login_JSON', {
      ac_name: params.username,
      ac_password: params.password
    });
  });

  declare('loginAsGuest', function (params) {
    return makeTypicalRequest('loginAsGuest_JSON', {
      source: params.source
    });
  });

  declare('register', function (params) {
    return makeTypicalRequest('register_JSON', {
      email: params.email,
      phone: params.phone,
      firstname: params.firstName,
      lastname: params.lastName,
      companyname: params.companyName
    });
  });

  declare('findCustomers', function (params) {
    return makeTypicalRequest('findCustomers_JSON', {
      email: params.email,
      phone: params.phone,
      firstName: params.firstName,
      lastName: params.lastName,
      companyName: params.companyName
    });
  });

  declare('getFavoriteRestaurants', function (params) {
    return makeTypicalRequest('getFavoriteRestaurants_JSON', {
      customerId: params.customerId
    });
  });

  declare('addFavoriteRestaurant', function (params) {
    return makeTypicalRequest('addFavoriteRestaurant_JSON', {
      customerId: params.customerId,
      restId: params.restaurantId,
      restName: params.restaurantName
    });
  });

  declare('deleteFavoriteRestaurant', function (params) {
    return makeTypicalRequest('deleteFavoriteRestaurant_JSON', {
      customerId: params.customerId,
      restId: params.restaurantId
    });
  });

  declare('getCustomerAddresses', function (params) {
    return makeTypicalRequest('getCustomerAddresses_JSON', {
      customerId: params.customerId
    });
  });

  declare('addCustomerAddress', function (params) {
    return makeTypicalRequest('addCustomerAddress_JSON', {
      customerId: params.customerId,
      streetNumber: params.streetNumber,
      address1: params.address1,
      address2: params.address2,
      city: params.city,
      stateCode: params.stateCode,
      postalCode: params.postalCode,
      phone: params.phone,
      longitude: params.longitude,
      latitude: params.latitude,
      geocode: params.geocode,
      return_details: params.return_details
    });
  });

  declare('getCustomerOrders', function (params) {
    return makeTypicalRequest('getCustomerOrders_JSON', {
      customerId: params.customerId,
      include_items: params.includeItems,
      limit: params.limit
    });
  });

  declare('getSavedOrders', function (params) {
    return makeTypicalRequest('getSavedOrders_JSON', {
      customerId: params.customerId,
      include_items: params.includeItems,
      limit: params.limit
    });
  });

  declare('addSavedOrder', function (params) {
    return makeTypicalRequest('addSavedOrder_JSON', {
      customerId: params.customerId,
      orderId: params.orderId,
      orderName: params.orderName
    });
  });

  declare('saveOrderAsQuote', function (params) {
    return makeNewStyleRequest('saveOrderAsQuote', {
      customerId: params.customerId,
      orderId: params.orderId,
      orderName: params.orderName,
      emailToCustomer: params.emailToCustomer
    });
  });

  declare('deleteSavedOrder', function (params) {
    return makeTypicalRequest('deleteSavedOrder_JSON', {
      customerId: params.customerId,
      orderId: params.orderId
    });
  });

  declare('cancelOrder', function (params) {
    return makeTypicalRequest('cancelOrder_JSON', {
      customerId: params.customerId,
      orderId: params.orderId
    });
  });

  declare('getCustomerFavorites', function (params) {
    return makeTypicalRequest('getCustomerFavorites_JSON', {
      customerId: params.customerId
    });
  });

  declare('getAvailableCoupons', function (params) {
    return makeTypicalRequest('getAvailableCoupons_JSON', {
      customerId: params.customerId
    });
  });

  declare('getOrderDetails', function (params) {
    return makeTypicalRequest('getOrderDetails_JSON', {
      customerId: params.customerId,
      orderId: params.orderId
    });
  });

  declare('getDefaultsForPostalCode', function (params) {
    return {
      url: '/mp/fpSearchBox/getDefaultsForPostalCode',
      type: 'POST',
      data: {
        postalCode: params.postalCode,
        json: true
      }
    };
  });


  //
  // Menu functions
  //

  declare('getLocationMenus', function (params) {
    return makeTypicalRequest('getLocationMenus_JSON', {
      restaurantId: params.restaurantId
    });
  });

  declare('getLocationMenuCategories', function (params) {
    return makeTypicalRequest('getLocationMenuCategories_JSON', {
      restaurantId: params.restaurantId,
      menuId: params.menuId
    });
  });


  //
  // Ordering
  //

  declare('startOrder', function (params) {
    return makeTypicalRequest('startOrder_JSON', {
      restaurantId: params.restaurantId,
      customerId: params.customerId,
      serviceId: params.serviceTypeId,
      hour: params.hour,
      minute: params.minute,
      day: params.day,
      month: params.month,
      year: params.year,
      destinationId: params.destination_id,
      source: params.source || 0,
      baseOrderId: params.baseOrderId,
      is_asap: params.is_asap || null
    });
  });

  declare('startPickupOrder', function (params) {
    return makeTypicalRequest('startPickUpOrder_JSON', {
      restaurantId: params.restaurantId,
      customerId: params.customerId,
      hour: params.hour,
      minute: params.minute,
      day: params.day,
      month: params.month,
      year: params.year,
      source: params.source || 0,
      baseOrderId: params.baseOrderId
    });
  });

  declare('startDeliveryOrder', function (params) {
    return makeTypicalRequest('startDeliveryOrder_JSON', {
      restaurantId: params.restaurantId,
      customerId: params.customerId,
      destinationId: params.destinationId,
      hour: params.hour,
      minute: params.minute,
      day: params.day,
      month: params.month,
      year: params.year,
      source: params.source || 0,
      baseOrderId: params.baseOrderId
    });
  });

});

