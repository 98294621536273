define('ndui/nutrition/nutrition_info',['require','flight/lib/component','flight/lib/logger','jquery','hbs!./nutrition_template'],function (require) {
  var defineComponent = require('flight/lib/component');
  var withLogging = require('flight/lib/logger');
  var $ = require('jquery');

  var nutritionTemplate = require('hbs!./nutrition_template');

  function nutritionInfo() {
    this.defaultAttrs({
      unavailableSelector: '.js-nutrition-unavailable',
      pendingSelector:     '.js-nutrition-pending',
      availableSelector:   '.js-nutrition-available'
    });

    this.handleNutritionData = function (event, data) {
      this.$node.children().hide();

      var nutrition = $.extend({}, data);
      var hasNutrition = nutrition._hasNutrition;
      delete nutrition._hasNutrition;

      if ($.isEmptyObject(nutrition)) {
        if (hasNutrition) {
          this.select('pendingSelector').show();
        } else {
          this.select('unavailableSelector').show();
        }
      } else {
        if (nutrition.allergens) {
          nutrition.allergens = $.map(nutrition.allergens, function (v) {
            return { name: v };
          });
        }

        Object.keys(nutrition).forEach(function (k) {
          nutrition['has_' + k] = true;
        });

        var html = nutritionTemplate(nutrition);
        this.select('availableSelector').html(html).show();
      }
    };

    this.after('initialize', function () {
      this.on(document, 'data-nutrition-totals', this.handleNutritionData);
    });
  }

  return defineComponent(nutritionInfo, withLogging);
});

