// Based on https://developers.facebook.com/docs/javascript/howto/requirejs

define('facebook',['require','exports','module','facebook-sdk'],function(require, exports, module) {
    var FB = require("facebook-sdk");
    var config = module.config();

    console.log("App ID: %s", config.appId);
    FB.init({
        appId: config.appId,
        status: false,
        cookie: true,
        xfbml: true,
        version: "v2.10"
    });

    return FB;
});

