/*global console */
define('nd/data/auth',['require','flightjs','jquery','ndlib/api'],function (require) {
  var flight = require('flightjs');
  var $ = require('jquery');

  var ndXtal = require('ndlib/api');


  function authComponent() {
    this.attributes({
      customerDataSelector: "#main-content-placeholder"
    });

    this.after('initialize', function () {
      var customerHolder = this.select("customerDataSelector");
      var data = customerHolder.length ? customerHolder.data() : {};
      if (data.customer) {
        this.customer = data.customer;
        this.customer.isGuest = !!data.customerIsGuest;
      } else {
        this.customer = null;
      }

      this.on('needCustomerData needCustomerId', function (ev, data) {
        data = data || {};
        var guestOK = !data.ignoreGuest;
        var isOK = this.customer && (guestOK || !this.customer.isGuest);
        if (isOK && !data.force) {
          if (data && data.redirectUrl) {
            window.location.href = data.redirectUrl;
          }

          this.trigger('customerAuthenticated', {
            customer: this.customer,
            customerId: this.customer.customersid
          });
          this.trigger('customerData', {
            customer: this.customer,
            customerId: this.customer.customersid
          });
        }
        else if (!data || !data.passive) {
          this.redirectUrl = data && data.redirectUrl;
          this.trigger('showAccountPrompt', data);
        }
      });

      this.on('signIn', function (ev, data) {
        ndXtal.login({
          username: data.username,
          password: data.password
        })
        .then(function (r) {
          if (r && r[0] > 0) {
            return { ok: true, customerId: r[0] };
          } else {
            return { ok: false };
          }
        })
        .then(this.handleLoginSuccess.bind(this))
        .fail(this.handleLoginFailure.bind(this));
      });

      this.on('createAccount', function (ev, data) {
        this.createAccount(data);
      });

      this.on('signInAsGuest', function () {
        ndXtal.loginAsGuest({})
        .then(function (r) { r.isGuest = true; return r; })
        .then(this.handleLoginSuccess.bind(this))
        .fail(this.handleLoginFailure.bind(this));
      });

      this.on('dataForeignAuthTokens', function (ev, data) {
        if (this.customer && !this.customer.isGuest) {
          // Don't relogin if the customer is already logged in.
          return;
        }

        var params = {
          provider: data.provider,
          auth_info: data.tokens
        };
        $.ajax({
          url: "/mp/ndXTAL/login_by_foreign_auth",
          type: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          data: JSON.stringify(params),
          processData: false
        })
        .then(function (r) {
          if (r && r > 0) {
            return { ok: true, customerId: r };
          } else {
            return { ok: false };
          }
        })
        .then(this.handleLoginSuccess.bind(this))
        .fail(this.handleLoginFailure.bind(this));
      });

      this.on('dataForeignAuthUnavailable', function (ev, data) {
        if (data && data.message) {
          this.trigger('userActionError', { message: data.message });
        }
      });
    });

    this.handleLoginSuccess = function (r) {
      if (!r.ok || !r.customerId || r.customerId <= 0) {
        return this.handleLoginFailure('Could not sign in.');
      }

      // Set the login cookie if needed.
      //if (r.cookie) {
      //  document.cookie = '__ac=' + encodeURIComponent(r.cookie) + ';path=/;secure';
      //}

      if (this.redirectUrl) {
        window.location.href = this.redirectUrl;
      } else {
        this.customer = {
          customersid: r.customerId,
          isGuest: !!r.isGuest
        };
        this.trigger('customerAuthenticated', {
          customer: this.customer,
          customerId: this.customer.customersid
        });
      }
    };

    this.handleLoginFailure = function (err) {
      console.log('Error signing in: %o', err);
      this.trigger('userActionError', {
        action: "signIn",
        message: 'Error signing in.'
      });
    };
  }

  return flight.component(authComponent);
});

